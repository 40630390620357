import styled from "styled-components"

export const PageTitle = styled.h1`
  font-family: 'Morganite Black', serif;
  font-size: 60px;
  padding: 35px 35px 20px;
  text-transform: uppercase;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  @media (min-width: 768px) {
    font-size: 180px;
    padding: 45px 35px 10px;
  }
`
